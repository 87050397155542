import axios from 'axios'; 

import React, { useState,useEffect } from 'react';
import '../components/pricing.css';
import Navpricing from '../components/navbarpricing';
// import Navwork from '../components/navbarwork';
// import Nav from './navbar';

import {login_pricing_home, login_sub, getProfilePicUrl, getUserName, isUserSignedIn} from '../components/firebase_login';
import CircularProgress from '@material-ui/core/CircularProgress';

import {checkout, stripe_checkout_download} from '../components/striperedirect';

import {connect} from 'react-redux';
// import Stripex from './stripex';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";

// import useFirebase from '../components/useFirebase';
import getFirebase from '../components/firebase';


import {navigate, Link } from 'gatsby';

import Signin from '../components/signin';

const firebase = getFirebase();

function sublog_click(e) {

      console.log('first login in sub')

    e.preventDefault();
    e.stopPropagation();

    var dd = localStorage.getItem('uid')
    var work_id = dd

    // if logged in and cn == 0
    if(work_id & parseInt(localStorage.getItem('cn')) == 0) {

      // open checkout page in new tab 
              // document.getElementById('gotostripe').click()

      document.getElementById("circlePricing").style.display = 'block'
      checkout(dd, email)
    }
    // if uid exists, i.e. logged in already || If email entered through form
    if(work_id || localStorage.getItem('email') ) {

      
      var email = localStorage.getItem('email')

          console.log(dd)

          // if videos still remaining

          if(parseInt(localStorage.getItem('cn')) > 0) {

            var d = {}

            // login with only email enter

            if(localStorage.getItem('uid')) {

                d['uid'] = dd

                navigate(`/workspace/?w=${dd}`, {
                    state: { videos: d }
                })

          } else {

              document.getElementById('signx').style.display = 'block'

          }

          } else {

            // document.getElementById("circlePricing").style.display = 'block'

            // just simply login OR Pay depending upon - used free trial
            // if(localStorage.getItem('valid') && parseInt(localStorage.getItem('valid')) == 1) {

            //   // open checkout page in new tab 
            //   document.getElementById('gotostripe').click()

            // }

            document.getElementById("circlePricing").style.display = 'block'
            checkout(dd, email)
        }

    } else {


      // alert('Please enter email to pay')
      document.getElementById('psubmitx').click()

   //    document.getElementById("circlePricing").style.display = 'block'
      // login_sub(e,props, firebase)

   //    document.getElementById("circlePricing").style.display = 'none'
    }


}

function Pricing(props){

    // const firebase = useFirebase();

    console.log('props in pricing', props)

 useEffect(() => {

 		document.title = 'TurtleClip | Pricing';

        // document.getElementById("raz").appendChild(script);
    // document.getElementsByName("email")[0].value = "oyeiitsme@gmail.com"

    // set value of subscribe in localstorage
    // if (localStorage.getItem('uid')) {
    //     var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

    //     axios.get(suburl).then(response => {

    //       console.log('subscribe in Renderdown', response.data.Item)
    //       localStorage.setItem('subscribe', response.data.Item.count.S)
      
    //     // if the item doesnt exist in subscribe table
    //     }).catch(e => {
    //       localStorage.setItem('subscribe', '0')
    //     })

    //     console.log('subscribe in Renderdown', localStorage.getItem('subscribe'))
    // }

    },);


   useEffect(() => {

      if (!firebase) return;


   		const work_id = localStorage.getItem('uid')
   		const sub_id = localStorage.getItem('cn')




   		if(work_id) document.getElementById('login_p').innerText = 'TRY NOW!'
   		else document.getElementById('login_p').innerText = 'LOG IN'

   		if(work_id && sub_id && parseInt(sub_id) > 0) document.getElementById('sublog').innerText = 'MY VIDEOS'

// On just pageload, with no login or email enter -
     if(!work_id && !sub_id) document.getElementById('sublog').innerText = 'SUBSCRIBE'

// => count has been checked on email enter | need reload on email enter - not needed
      if(!work_id && sub_id && parseInt(sub_id) > 0) document.getElementById('sublog').innerText = 'LOG IN'
// => if account exist but vid=0 -- SUBSCRIBE OR no data found => can get free video -- LOGIN
   		if(sub_id && parseInt(sub_id) == 0) document.getElementById('sublog').innerText = 'SUBSCRIBE'
   		
      // if due to error, sub_id = null & work_id is there
      if(work_id && !sub_id) document.getElementById('sublog').innerText = 'SUBSCRIBE'

   		try {
		document.getElementById('login_p').addEventListener('click', (e) => {

        e.preventDefault();
        e.stopPropagation();


        var d = {}
// if already logged in
        if( work_id) {

        	d['uid'] = work_id

        	navigate(`/workspace/?w=${work_id}`, {
          		state: { videos: d }
        	})


        } else {

        // document.getElementById("circlePricing").style.display = 'block'
        // login_pricing_home(e, props, firebase)
                document.getElementById('signx').style.display = 'block'
		
    }


		});

		} catch {}




},[firebase]) 




useEffect(() => {

document.getElementById('pformx').addEventListener('submit', (e) => {

    e.preventDefault();
    e.stopPropagation();


    let em = document.getElementById("pemailtrx").value
    localStorage.setItem('email', em)

    // document.getElementById('pcheckpay').href = "https://gumroad.com/l/vDrsj/?wanted=true&email=" + em + '&user=' + em

  const payurl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + em

// if no email => user hasnt been logged in yet?

    axios.get(payurl).then(res => {
    
      console.log('json', res.data)
      // console.log('r', res.data.Item.jsonpath)

      var cn = res.data.Item.count.S
      cn = parseInt(cn)

      localStorage.setItem('cn', cn)


      if (cn ==0) {
        localStorage.setItem('valid', 1)
        document.getElementById('sublog').innerText = 'SUBSCRIBE'
    } else {

      document.getElementById('sublog').innerText = 'LOG IN'
      document.getElementById('signx').style.display = 'block'

    }


    }).catch(e => {
      var cn = 0

      localStorage.setItem('cn', cn)

      document.getElementById('sublog').innerText = 'LOG IN'
      document.getElementById('signx').style.display = 'block'

    }) 


// add entry in table, for this email update/add the media file

    alert('email added')
})

// window.location.search.split('?v=')[1] + '.json'

},[])




// // if user is logged in, no need to enter email - Dont show form then
useEffect(() => {
  let em = localStorage.getItem('login')
  if(em && em == '1') {
    // // document.getElementById("emailtrx").value = em
    // document.getElementById("pemailtrx").disabled = true;
    // document.getElementById('psubmitx').style.display = 'none';
    document.getElementById('pformx').style.display = 'none' ;
  } 

//   let em2 = localStorage.getItem('email')
//   if(em2) {document.getElementById("pemailtrx").value = em2;

// // one time gumroad payment
//       // document.getElementById('pcheckpay').href = "https://gumroad.com/l/vQInTi?wanted=true" + "&email=" + em2 + '&user=' + em2

//       // document.getElementById('pcheckpay').href = "https://gumroad.com/l/sqdysl?wanted=true" + "&email=" + em2 + '&user=' + em2

// }
  },[])



//    useEffect(() => {

//       if (!firebase) return;

// document.getElementById('gumpay').addEventListener('click', (e) => {

//     e.preventDefault();
//     e.stopPropagation();



// // if alredy paid, translate directly
//     if(!localStorage.getItem('email')) {
//       // alert('Please enter email to pay')
//       document.getElementById('psubmitx').click()

//   } else {

    
//     document.getElementById("pcheckpay").click();
    
  

//   }
// })




// },[firebase]) 




return (

	<div>

	<Navpricing props={props} />

	<CircularProgress style={{'display':'none'}} id="circlePricing" className="Mpbar" />


  <div className="tooltip toool">


  <form id='pformx'>
    <input type='email' id="pemailtrx" placeholder="email to create account" required oninvalid="this.setCustomValidity('Please Enter valid email')"/>

    <input id='psubmitx' type='submit' value='submit' />
  </form>

  <span className="tooltiptext emailt">Enter email to create account</span>

  </div>

  <Signin props={props} />

	<div id="prizing">

	<div className='tier'>
		<div className="priceTag">FREE</div>
		<div className="priceVal">$0</div>
		<div className='nv'>1 video</div>
		<div>Automatic transcription</div>
    <div>Automatic translation</div>
		<div>Add text</div>
		<div>Add and style top heading</div>
		<div>Video resizing</div>
		<div>Progress bar</div>
		<div>Style and position subtitle</div>
    <div>Online subtitle editor</div>
		<div className='wm'>No Watermark</div>

    <div id="login_p"></div>


	</div>

	<div className='tier'>
		<div className="priceTag">PRO</div>

<a href='/pay' target="_blank" id="gotostripe" ></a>

		<div className="priceVal">$19<span className='permonth'>/Month</span></div>
		<div className='nv'>10 videos</div>
		<div>Automatic transcription</div>
    <div>Automatic translation</div>
		<div>Add text</div>
		<div>Add and style top heading</div>
		<div>Video resizing</div>
		<div>Progress bar</div>
		<div>Style and position subtitle</div>
    <div>Online subtitle editor</div>
		<div>No Watermark</div>

<div onClick={sublog_click} id="sublog"></div>

	</div>
	
	</div>



	</div>

)
}


const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


Pricing.displayName = "Pricing";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Pricing));
